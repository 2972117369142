import React from 'react'
import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom'
import './App.css'
import ConsentJourney from './views/ConsentJourney/ConsentJourney'
import Finalize from './views/Finalize'

const MainRouter = (): JSX.Element => {
  return (
    <Router>
      <div>
        {/* <NavBar /> */}
        <div>
          <Switch>
            <Route path="/" exact component={ConsentJourney} />
            <Route exact path="/finalize" component={Finalize} />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  )
}

export default MainRouter
