/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getQueryStringValue } from '../../utils'
import Loader from '../../components/generic/Loader'
import { REACT_APP_SESSION_API_URL } from '../../consts'
import parentComms from '../../lib/parentComms'
import { consentJourneyContext } from './ConsentJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    padding: spacing(4),
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    placeContent: 'space-evenly',
  },
  bankLogo: {
    display: 'flex',
    width: 100,
    height: 100,
    padding: spacing(0.5),
    margin: spacing(0.5),
    alignSelf: 'center',
    borderRadius: 50,
    objectFit: 'cover',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  loaderContainer: {
    display: 'flex',
    flex: 1,
  },
}), { name: 'WaitingSessionFinished' })

const WaitingSessionFinished = (): JSX.Element => {
  const classes = useStyles()

  const {
    stage,
    setStage,
    chosenBank,
    session,
    setSession,
  } = useContext(consentJourneyContext)

  const [requestNumber, setRequestNumber] = useState(0)

  const checkSessionStatus = useCallback(async (): Promise<void> => {
    try {
      setRequestNumber(requestNumber + 1)

      const sessionId = getQueryStringValue('sessionId')
      const token = getQueryStringValue('token')

      const config = {
        headers: new Headers({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }),
      }

      const sessionResponse = await fetch(`${REACT_APP_SESSION_API_URL}/get/${sessionId}`, config)

      const session = await sessionResponse.json()

      console.log(session)

      if (session?.session?.state === 'finished' || session?.session?.state !== 'active') {
        setSession(session?.session)

        setStage(Math.floor(stage + 1))
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : error

      parentComms('journey', 'fail', { error: message })
    }
  }, [requestNumber, setSession, setStage, stage])

  useEffect(() => {
    const timer = setTimeout(() => {
      return !session && checkSessionStatus()
    }, 2000)

    return () => clearTimeout(timer)
  }, [checkSessionStatus, requestNumber, session])

  return (
    <div className={classes.root}>
      {chosenBank?.image && <img src={chosenBank.image} className={classes.bankLogo} alt='logo' />}
      <div className={classes.container}>
        <div className={classes.loaderContainer} >
          <Loader />
        </div>
        <div className={classes.textContainer} >
          <Typography variant='h6'>When the process is finished the status will be updated.</Typography>
        </div>
      </div>
    </div>
  )
}

export default WaitingSessionFinished
