import React, { useCallback, useContext, useState } from 'react'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  SvgIcon,
  Theme,
  Typography
} from '@mui/material'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined'
import { makeStyles } from '@mui/styles'
import CustomButton from '../../components/generic/CustomButton'
import { consentJourneyContext } from './ConsentJourney'


const useStyles = makeStyles(({ spacing }: Theme) => ({
  disclaimerContainer: {
    display: 'flex',
    padding: spacing(4.5),
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    animation: '$textAnimation 1900ms 1',
  },
  dialogSubTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'left',
    alignItems: 'center',
  },
  dialogSubTitle: {
    display: 'flex',
    marginBottom: 0,
    fontWeight: 'bold',
    paddingLeft: spacing(1),
  },
  dialogContent: {
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.5)',
    paddingLeft: spacing(4),
    paddingBottom: spacing(3),
  },
  privacyPolicyContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: spacing(),
  },
  '@keyframes textAnimation': {
    '0%': {
      opacity: 0.1,
    },
    '100%': {
      opacity: 1,
    }
  }
}), { name: 'DisclaimerDialog' })

const DisclaimerDialog = (): JSX.Element => {
  const classes = useStyles()

  const { setStage, stage } = useContext(consentJourneyContext)

  const [isConfirmPolicy, setIsConfirmPolicy] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleClickGetStarted = useCallback((): void => {
    if (!isConfirmPolicy) {
      setIsError(true)

      return
    }

    setStage(stage + 1)

  }, [isConfirmPolicy, setStage, stage])

  const handleChangeCheckbox = useCallback((): void => {
    setIsConfirmPolicy(!isConfirmPolicy)
  }, [isConfirmPolicy])

  const handleCloseError = useCallback((): void => {
    setIsError(false)
  }, [])

  return (
    <div className={classes.disclaimerContainer}>
      <div>
        <div className={classes.dialogSubTitleContainer}>
          <SvgIcon component={VerifiedUserOutlinedIcon} viewBox="0 0 24 24" />
          <Typography variant='h6' className={classes.dialogSubTitle} sx={{ fontWeight: 'bold' }}>Secure</Typography>
        </div>
        <Typography className={classes.dialogContent}>
          Encryption helps protect your personal financial data
        </Typography>
        <div className={classes.dialogSubTitleContainer}>
          <SvgIcon component={FingerprintOutlinedIcon} viewBox="0 0 24 24" />
          <Typography variant='h6' className={classes.dialogSubTitle} sx={{ fontWeight: 'bold' }}>Private</Typography>
        </div>
        <Typography className={classes.dialogContent}>
          Your credentials will never be made accessible to Open-Finance
        </Typography>
      </div>
      <div>
        <Divider />
        <FormControlLabel
          value="start"
          control={<Checkbox checked={isConfirmPolicy} onChange={handleChangeCheckbox} />}
          className={classes.privacyPolicyContainer}
          label={
            <Typography variant='caption'>
              {'By selecting you agree to the Open Finance End User Privacy Policy'}
            </Typography>
          }
        />
        <div style={{ paddingTop: 22 }}>
          <CustomButton
            title='Get started'
            onClick={handleClickGetStarted}
            errorMessage='Privacy Policy is required'
            isError={isError}
            handleCloseError={handleCloseError}
          />
        </div>
      </div>
    </div >
  )
}

export default DisclaimerDialog
