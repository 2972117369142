
const {
  REACT_APP_AUTH0_DOMAIN: AUTH0_DOMAIN = '',
  REACT_APP_AUTH0_CLIENT_ID: AUTH0_CLIENT_ID = '',
  REACT_APP_REDIRECT_URI: REDIRECT_URI,
  REACT_APP_PSD2_CONSENTS_URL = 'http://localhost:3020',
  REACT_APP_SESSION_API_URL = 'http://localhost:3030',
} = process.env

export {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  REDIRECT_URI,
  REACT_APP_PSD2_CONSENTS_URL,
  REACT_APP_SESSION_API_URL,
}