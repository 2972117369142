/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { CardActionArea, List, ListItem, Theme } from '@mui/material'
import CustomCard from '../../components/generic/CustomCard'
import { banks as allBanks } from '../../data/banks'
import SearchBar from '../../components/generic/Search'
import { Bank } from '../../@types'
import { consentJourneyContext } from './ConsentJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    padding: spacing(1),
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    opacity: 0,
    animation: '$fade-left 0.25s ease-in',
    animationFillMode: 'forwards',
  },
  '@keyframes fade-left': {
    from: {
      left: -20
    },
    to: {
      opacity: 1,
      left: 0,
    },
  },
}), { name: 'ChooseBank' })

const ChooseBank = (): JSX.Element => {
  const classes = useStyles()

  const {
    setStage,
    stage,
    setChosenBank
  } = useContext(consentJourneyContext)

  const [banks, setBanks] = useState<Array<Bank>>([])
  const [banksFilter, setBanksFilter] = useState<string>('')

  useEffect(() => {
    setBanks(allBanks)
  }, [])

  useEffect(() => {
    if (banksFilter) {
      const filtered = allBanks.filter(bank => Object.values(bank).find((propertyValue) => {
        return propertyValue.includes(banksFilter.toLowerCase())
      }))

      setBanks(filtered)
    }
    else {
      setBanks(allBanks)
    }
  }, [banksFilter])

  const handleClickCard = (bankId: string): void => {
    const chosenBank = banks.find(bank => bank._id === bankId)

    if (chosenBank)
      setChosenBank(chosenBank)

    setStage(Math.floor(stage + 1))
  }

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', alignSelf: 'center' }}>
      </div>
      <List
        className={classes.listContainer}
        subheader={<SearchBar placeHolder='Search Bank' value={banksFilter} setValue={setBanksFilter} />}
      >
        {
          banks.map(({ _id, bankId, site, image }, indx) => {
            return (
              <ListItem className={classes.listItem} key={_id} sx={{ animationDelay: `${indx * 150}ms` }}>
                <CardActionArea onClick={() => handleClickCard(_id)}>
                  <CustomCard image={image} title={bankId} subTitle={site} />
                </CardActionArea>
              </ListItem>
            )
          })
        }
      </List>
    </div>
  )

}

export default ChooseBank
