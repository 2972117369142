import React, { useCallback, useContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { FormControl, FormHelperText, TextField, Theme, Typography } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { DatePicker } from '@mui/lab'
import CustomCard from '../../components/generic/CustomCard'
import CustomButton from '../../components/generic/CustomButton'
import isValidNationalID from '../../lib/is-valid-national-id'
import { consentJourneyContext } from './ConsentJourney'

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    padding: spacing(4.5),
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-evenly',
    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  },
  bankCardContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'space-evenly',
    height: '100%',
    textAlign: 'center',
    flex: 1,
  },
}), { name: 'PersonalInfo' })


const styles = {
  titleStyle: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: 'bold'
  }
}

const tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 1))

const PersonalInfo = (): JSX.Element => {
  const classes = useStyles()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errorMessage, setErrorMessage] = useState<Record<string, any>>({})

  const {
    stage,
    setStage,
    chosenBank,
    setUntilDate,
    untilDate,
    nationalId,
    setNationalId,
  } = useContext(consentJourneyContext)

  const handleChangeDate = useCallback((date: Date | null): void => {
    setUntilDate(date as Date)
  }, [setUntilDate])

  const handleChangeNationalId = useCallback(({ target }) => {
    if (target.value.length <= 9 && (Number(target.value) || target.value == ''))
      setNationalId(target.value)
  }, [setNationalId])

  const isInvalidInputs = useCallback((): boolean => {
    if (!isValidNationalID(nationalId)) {
      setErrorMessage({ nationalId: 'Invalid national ID' })

      return true
    }

    // eslint-disable-next-line max-len
    if (!untilDate || untilDate.toString().includes('Invalid Date') || new Date(untilDate).getTime() <= new Date().getTime()) {
      setErrorMessage({ untilDate: 'Expired date is not valid' })

      return true
    }

    setErrorMessage({})

    return false
  }, [nationalId, untilDate])

  const handleClickContinue = useCallback((): void => {
    if (isInvalidInputs()) {
      return
    }

    setStage(stage + 1)
  }, [isInvalidInputs, setStage, stage])

  return (
    <div className={classes.root}>
      <div className={classes.bankCardContainer}>
        {chosenBank &&
          <CustomCard
            image={chosenBank.image}
            title={chosenBank.bankId}
            subTitle={chosenBank.site}
          />}
      </div>
      <div className={classes.dateContainer}>
        <Typography sx={styles.titleStyle}>
          Insert a national ID and until when would you like to share your data.
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 2 }}>
          <TextField
            required
            error={!!errorMessage.nationalId}
            fullWidth
            label="National ID"
            name="id"
            value={nationalId}
            onChange={handleChangeNationalId}
            sx={{ paddingBottom: 5 }}
            helperText={errorMessage.nationalId && errorMessage.nationalId}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl
              error={!!errorMessage.untilDate}
            >
              <DatePicker
                label="Choose Expired Date..."
                value={untilDate}
                onChange={handleChangeDate}
                minDate={tomorrowDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <FormHelperText error={!!errorMessage.untilDate}>
                {errorMessage.untilDate}
              </FormHelperText>
            </FormControl>
          </LocalizationProvider>
        </div>
      </div>
      <CustomButton
        title='Continue'
        onClick={handleClickContinue}
      />
    </div >
  )
}

export default PersonalInfo
