/* eslint-disable max-len */
import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'
import SweetAlert from 'react-bootstrap-sweetalert'
import openFinanceLogo from '../../assets/icons/open-finance.png'
import parentComms from '../../lib/parentComms'
import { consentJourneyContext } from './ConsentJourney'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center'
  },
  logo: {
    width: 200,
  }
}), { name: 'JourneyFinished' })

const JourneyFinished = (): JSX.Element => {
  const classes = useStyles()

  const { session } = useContext(consentJourneyContext)

  const clickConfirm = (): void => {
    console.log('click')
    
    if (session?.state === 'finished') {
      parentComms('journey', 'success', { session })
      parentComms('journey', 'close')
    } else {
      parentComms('journey', 'fail', { error: 'the journey have failed', state: session?.state })
      parentComms('journey', 'close')
    }
  }

  return (
    <div className={classes.root}>
      <img src={openFinanceLogo} className={classes.logo} alt='logo' />
      <div>
        {session?.state === 'finished' ?
          <SweetAlert
            success
            title="SUCCESS"
            onConfirm={clickConfirm}
            show
            style={{ width: '380px' }}
            customButtons={(
              <button style={{ backgroundColor: 'black', color: 'white', borderRadius: '11px', border: 'none', padding: '0.5rem 1rem', cursor: 'pointer' }} onClick={clickConfirm}>
                Confirm
              </button>
            )}
          >
            You can now close this window and go back.
          </SweetAlert>
          :
          <SweetAlert
            error
            title="FAILED"
            onConfirm={clickConfirm}
            show
            style={{ width: '380px' }}
            customButtons={(
              <button style={{ backgroundColor: 'black', color: 'white', borderRadius: '11px', border: 'none', padding: '0.5rem 1rem', cursor: 'pointer' }} onClick={clickConfirm}>
                Confirm
              </button>
            )}
          >
            Something wrong, try again latter.
          </SweetAlert>
        }
      </div>
    </div >
  )
}

export default JourneyFinished
