/* eslint-disable max-len */
import React, { useCallback, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { SvgIcon, Theme, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import EditIcon from '@mui/icons-material/Edit'
import DateRangeIcon from '@mui/icons-material/DateRange'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import CustomCard from '../../components/generic/CustomCard'
import CustomButton from '../../components/generic/CustomButton'
import parentComms from '../../lib/parentComms'
import { consentJourneyContext } from './ConsentJourney'

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    padding: spacing(4),
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',

    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  },
  editIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '25px',
    height: '25px',
    borderRadius: '50px',
    right: '-7px',
    top: '-7px',
    backgroundColor: '#f3f3f3',
    boxShadow: '0px 0px 5px #919191',
  },
  bankCardContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 10,
  },
  textContainer: {
    borderRadius: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    margin: 10,
  },
  textRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
    color: '#707070',
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.5,
    width: '100%',
  },
  border: {
    opacity: 0.5,
    borderBottom: '1px solid black',
    width: '100%',
  },
  content: {
    padding: '0 10px 0 10px',
  },
}), { name: 'Confirm' })


const Confirm = (): JSX.Element => {
  const classes = useStyles()

  const {
    stage,
    setStage,
    chosenBank,
    nationalId,
    untilDate,
  } = useContext(consentJourneyContext)

  const handleClickConfirm = useCallback((): void => {
    setStage(stage + 1)
  }, [setStage, stage])

  const handleClickDeny = useCallback(() => {
    parentComms('journey', 'fail', { error: 'user deny' })
  }, [])

  type TextRowProps = { icon: React.ElementType, iconSize: number, text: string, value?: string | null }

  const TextRow = ({ icon, iconSize, text = '', value = null }: TextRowProps): JSX.Element => (
    <div className={classes.textRowContainer}>
      <div style={{ display: 'flex', alignItems: value ? '' : 'baseline' }}>
        <SvgIcon component={icon} sx={{ fontSize: iconSize }} />
        <Typography variant='subtitle2' sx={{ paddingLeft: 1 }}>{text}</Typography>
      </div>
      {value && <Typography variant='subtitle2'>{value}</Typography>}
    </div>)

  return (
    <div className={classes.root}>
      <div className={classes.bankCardContainer}>
        {chosenBank &&
          <CustomCard
            image={chosenBank.image}
            title={chosenBank.bankId}
            subTitle={chosenBank.site}
          />}
      </div>
      <div>
        <div className={classes.textContainer} style={{ backgroundColor: 'rgb(243 243 243)', position: 'relative' }}>
          <div className={classes.editIcon} onClick={() => setStage(stage - 1)}>
            <SvgIcon component={EditIcon} fontSize='small' style={{ cursor: 'pointer' }} />
          </div>
          <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}> Personal information</Typography>
          <TextRow icon={AccountCircleIcon} iconSize={20} text='ID:' value={nationalId} />
          <TextRow icon={DateRangeIcon} iconSize={20} text='Until date:' value={untilDate?.toLocaleDateString('en-US')} />

        </div>
        <div className={classes.textContainer} style={{ backgroundColor: '#d2e6fb' }}>
          <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>Authenticate with {chosenBank?.bankId} Bank</Typography>
          <TextRow icon={FiberManualRecordIcon} text={`You will be securely transferred to ${chosenBank?.bankId} Bank.`} iconSize={7} />
          <TextRow icon={FiberManualRecordIcon} text='You will be required to authenticate.' iconSize={7} />
          <TextRow icon={FiberManualRecordIcon} text='Once authenticated, you will be redirected back to Open-Finance' iconSize={7} />
        </div>
      </div>
      <CustomButton
        title='Confirm'
        onClick={handleClickConfirm}
      />
      <div className={classes.dividerContainer}>
        <div className={classes.border} />
        <span className={classes.content}>
          OR
        </span>
        <div className={classes.border} />
      </div>
      <CustomButton
        title='Deny'
        onClick={handleClickDeny}
        style={{ backgroundColor: 'white', borderWidth: 1, borderColor: '#cd4949', color: '#cd4949' }}
      />
    </div >
  )
}

export default Confirm
