import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Alert, Snackbar, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { withRouter } from 'react-router'
import Loader from '../../components/generic/Loader'
import { getQueryStringValue } from '../../utils'
import { REACT_APP_PSD2_CONSENTS_URL } from '../../consts'
import CustomButton from '../../components/generic/CustomButton'
import { consentJourneyContext } from './ConsentJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    padding: spacing(4),
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    placeContent: 'space-evenly',
  },
  bankLogo: {
    display: 'flex',
    width: 100,
    height: 100,
    padding: spacing(0.5),
    margin: spacing(0.5),
    alignSelf: 'center',
    borderRadius: 50,
    objectFit: 'cover',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  loaderContainer: {
    display: 'flex',
    flex: 1,
  },
}), { name: 'LoadingScreen' })

const LoadingScreen = (): JSX.Element => {
  const classes = useStyles()

  const [isError, setIsError] = useState<boolean>(false)
  const [redirect, setRedirect] = useState('')

  const {
    stage,
    setStage,
    chosenBank,
    nationalId,
    untilDate,
  } = useContext(consentJourneyContext)

  const clickToContinue = (): void => {
    window.open(redirect, '_blank')?.focus()
    setStage(stage + 1)
  }

  const createConsent = useCallback(async (): Promise<void> => {
    const token = getQueryStringValue('token')
    const sessionId = getQueryStringValue('sessionId')

    const consentRequestBody = {
      psuId: nationalId,
      combinedServiceIndicator: false,
      accounts: [],
      transactions: [],
      balances: [],
      frequencyPerDay: 100,
      recurringIndicator: true,
      validUntil: untilDate,
      financialInstitutionId: chosenBank?._id,
      webhookUrl: 'www.open-finance.com',
      sessionId,
    }

    const config: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(consentRequestBody)
    }

    try {
      const consentResponse = await fetch(`${REACT_APP_PSD2_CONSENTS_URL}`, config)
      const consent = await consentResponse.json()

      const { scaOAuth } = consent

      if (scaOAuth) {
        setRedirect(scaOAuth)
      }
      else {
        setIsError(true)
        setTimeout(() => setStage(stage - 1), 2000)
      }
    } catch (error) {
      setIsError(true)
      setTimeout(() => setStage(stage - 1), 2000)
    }
  }, [chosenBank?._id, nationalId, setStage, stage, untilDate])

  useEffect(() => {
    createConsent()
  }, []) // eslint-disable-line

  return (
    <div className={classes.root}>
      {chosenBank?.image && <img src={chosenBank.image} className={classes.bankLogo} alt='logo' />}
      <div className={classes.container}>
        {!isError && (
          <>
            <div className={classes.loaderContainer} >
              {!redirect && (<Loader />)}
            </div>
            <div className={classes.textContainer} >
              <Typography variant='h6'>
                You will be transferred to the bank to complete the process.
              </Typography>
              {!redirect && (
                <Typography variant='h6'>
                  Please hold while we redirect you
                </Typography>
              )}
              {redirect && (
                <Typography variant='h6'>
                  If you are not redirected, please click the button below.
                </Typography>
              )}
            </div>
          </>
        )}
        {isError && (
          <div className={classes.textContainer} >
            <Typography variant='h6'>
              It seems that something went wrong.
            </Typography>
          </div>
        )}
      </div>
      {redirect && (
        <div className={classes.root}>
          <CustomButton
            title='Continue'
            onClick={clickToContinue}
          />
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isError}
        onClose={() => setIsError(false)}
        key={'bottom' + 'center'}
      >
        <Alert severity='error'>Something went wrong, taking you back a step</Alert>
      </Snackbar>
    </div >
  )
}

export default withRouter(LoadingScreen)
