const parentComms = (
  source: 'journey',
  type: 'abort' | 'success' | 'fail' | 'close',
  payload = {},
): void => {
  switch (source) {
  case 'journey': {
    window.parent?.postMessage({ source, type, payload }, '*')
    break
  }
  default: {
    break
  }
  }
}

export default parentComms