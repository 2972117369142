/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { CircularProgress } from '@mui/material'
import getQuery from '../../lib/get-query'
import { REACT_APP_PSD2_CONSENTS_URL, REACT_APP_SESSION_API_URL } from '../../consts'

const styles = {
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
} as {
  root: React.CSSProperties
}

function Finalize(): JSX.Element {
  const { code, state } = getQuery(useLocation().search)
  const [loading, setLoading] = useState<false | string>(false)
  const [error, setError] = useState<false | string>(false)
  const [done, setDone] = useState<boolean>(false)

  useEffect(() => {
    if (done) {
      setTimeout(() => {
        window.close()
      }, 3000)
    }
  }, [done])

  useEffect(() => {
    if (code && state) {
      (async (): Promise<void> => {
        try {
          setLoading('Finalizing consent creation...')

          const finalizeResponse = await fetch(
            `${REACT_APP_PSD2_CONSENTS_URL}/finalize?code=${code}&state=${state}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )

          if (finalizeResponse.status === 200) {
            const { sessionId, consentId, consentStatus } = await finalizeResponse.json()

            setLoading('Finishing process...')

            const finishResponse = await fetch(
              `${REACT_APP_SESSION_API_URL}/finish?sessionId=${sessionId}&consentId=${consentId}&consentStatus=${consentStatus}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )

            if (finishResponse.status === 200) {
              setDone(true)
            } else {
              setError(`ERROR! Could not finish process! Reason: ${finishResponse.statusText}`)
            }
          } else {
            setError(`ERROR! Could not finalize consent creation! Reason: ${finalizeResponse.statusText}`)
          }

          setLoading(false)
        } catch (error: any) { // eslint-disable-line
          console.error(error)
          setError(`ERROR! ${error.message}`)
          setLoading(false)
        }
      })()
    }
  }, [code, state])

  if (!code || !state) {
    return (
      <div style={styles.root}>
        <p>
          An error occurred, query is missing <code>&quot;code&quot;</code> or <code>&quot;state&quot;</code> params.
        </p>
      </div>
    )
  }

  return (
    <div style={styles.root}>
      {loading && <CircularProgress color='inherit' />}
      <p>
        {done ? (
          <>
            Succesfully processed final consent operation!
            <br />
            You can safely close this window 😃
          </>
        ) : error ? (
          error
        ) : loading ? (
          loading
        ) : null}
      </p>
    </div>
  )
}

export default Finalize
