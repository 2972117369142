/* eslint-disable max-len */
export const banks = [
  // {
  //   _id: 'b79bb5c9-eb30-4e5f-8615-02484ae0f5f4',
  //   bankId: 'Mizrahi',
  //   site: 'www.mizrahi-tefahot.co.il',
  //   image: 'https://www.maala.org.il/wp-content/uploads/2015/07/%D7%91%D7%A0%D7%A7-%D7%9E%D7%96%D7%A8%D7%97%D7%99-%D7%98%D7%A4%D7%97%D7%95%D7%AA.png',
  //   number: '20',
  // },
  {
    _id: 'b79bb5c9-eb30-4e5f-8615-02484ae0f5f8',
    bankId: 'Open Finance Bank',
    site: 'www.open-finance.ai',
    image: 'https://open-finance-media.s3.eu-central-1.amazonaws.com/logos/transparent-logo-black.png',
    number: '20',
  },
  // {
  //   _id: 'discount',
  //   bankId: 'Discount',
  //   site: 'www.discountbank.co.il',
  //   image: 'https://yt3.ggpht.com/ytc/AKedOLTFpzXVupjFjsCp_A-YU565qvRtDHJbtaUdcb_X=s900-c-k-c0x00ffffff-no-rj',
  //   number: '20',
  // },
  // {
  //   _id: 'fibi',
  //   bankId: 'Binleumi',
  //   site: 'www.fibi.co.il',
  //   image: 'https://www.theta1.co.il/wp-content/uploads/2021/02/%D7%91%D7%99%D7%A0%D7%9C%D7%90%D7%95%D7%9E%D7%99-%D7%9C%D7%95%D7%92%D7%95.jpg',
  //   number: '20',
  // },
  // {
  //   _id: 'bankhapoalim',
  //   bankId: 'Bank Hapoalim',
  //   site: 'www.bankhapoalim.co.il',
  //   image: 'https://yt3.ggpht.com/ytc/AKedOLRXzOxFGt3F_UHfgcGgD2fmk9t3DfCGewdbAKqo=s900-c-k-c0x00ffffff-no-rj',
  //   number: '20',
  // },
  // {
  //   _id: 'mercantile',
  //   bankId: 'Mercantile',
  //   site: 'www.mercantile.co.il',
  //   image: 'https://ramot-mall.co.il/wp-content/uploads/2019/12/LOGO-43.png',
  //   number: '20',
  // },
  // {
  //   _id: 'pepper',
  //   bankId: 'Pepper',
  //   site: 'www.pepper.co.il',
  //   image: 'https://www.pepper.co.il/wp-content/themes/pepper/images/logo.svg',
  //   number: '20',
  // },
  // {
  //   _id: 'jerusalem',
  //   bankId: 'Jerusalem',
  //   site: 'www.bankjerusalem.co.il',
  //   image: 'https://mbr.co.il/wp-content/uploads/2020/09/13212313.jpg',
  //   number: '20',
  // },
  // {
  //   _id: 'yahav',
  //   bankId: 'Yahav',
  //   site: 'www.bank-yahav.co.il',
  //   image: 'https://www.bank-yahav.co.il/SiteData/1/Templates/Yahav/img/logo.png',
  //   number: '20',
  // },
  // {
  //   _id: 'igud',
  //   bankId: 'Igud',
  //   site: 'www.unionbank.co.il',
  //   image: 'https://www.unionbank.co.il/Igud/Img/Logo_Main.png',
  //   number: '20',
  // },
  // {
  //   _id: 'digibank',
  //   bankId: 'Digital Bank',
  //   site: 'www.digibank.co.il/',
  //   image: 'https://www.digibank.co.il/Content/images/footer-logo.svg',
  //   number: '20',
  // },
]
