/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line no-unused-vars
type GetQuery = (queryStr: string) => Record < string, any >

const getQuery: GetQuery = (queryStr) => {
  if (queryStr) {
    const queryObj: Record<string, any> = {}
    const queryItems: string[] = (
      queryStr[0] === '?' ? queryStr.substring(1, queryStr.length) : queryStr
    ).split('&')

    queryItems.forEach((str) => {
      const [key, val] = str.split('=')

      queryObj[key] = val
    })

    return queryObj
  } else {
    console.warn('Query string is not defined')

    return {}
  }
}

export default getQuery
