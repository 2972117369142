/* eslint-disable max-len */
import React, { createContext, useCallback, useState } from 'react'
import { Dialog, IconButton, MobileStepper, Slide, SlideProps, Theme, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ArrowBack, Close } from '@mui/icons-material'
import useWindowSize from '../../hooks/useWindowSize'
import { Bank, consentJourneyContextType, Session } from '../../@types'
import parentComms from '../../lib/parentComms'
import DisclaimerDialog from './DisclaimerDialog'
import ChooseBank from './ChooseBank'
import PersonalInfo from './PersonalInfo'
import LoadingScreen from './LoadingScreen'
import WaitingSessionFinished from './WaitingSessionFinished'
import Confirm from './Confirm'
import JourneyFinished from './JourneyFinished'

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 660,
    width: 400,
    [breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      alignSelf: 'center',
    },
  },
  appBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2),
    zIndex: 3,
    border: 0,
  },
  logoContainer: {
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    userSelect: 'none',
    '& > div': {
      letterSpacing: '1.5px',
      fontSize: 24,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  icon: {
    '& svg': {
      width: 25,
      height: 25,
    },
  }
}), { name: 'ConsentJourney' })

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (<Slide direction="up" ref={ref} {...props} />))

export const consentJourneyContext = createContext<consentJourneyContextType>({} as consentJourneyContextType)

const ConsentJourney = (): JSX.Element => {
  const classes = useStyles()
  const windowSize = useWindowSize()

  const [open, setOpen] = useState(true)
  const [stage, setStage] = useState(0)
  const [chosenBank, setChosenBank] = useState<Bank | null>(null)
  const [untilDate, setUntilDate] = useState<Date | null>(null)
  const [nationalId, setNationalId] = useState<string>('')
  const [session, setSession] = useState<Session | null>(null)

  const handleCloseDialog = useCallback((): void => {
    parentComms('journey', 'abort')
    parentComms('journey', 'close')
  }, [])

  const backClicked = useCallback((): void => {
    if (stage === 0) return handleCloseDialog()

    setStage(Math.floor(stage - 1))
  }, [stage, handleCloseDialog])

  const contextProviderValue = {
    open,
    setOpen,
    stage,
    setStage,
    chosenBank,
    setChosenBank,
    untilDate,
    setUntilDate,
    nationalId,
    setNationalId,
    session,
    setSession,
  }

  return (
    <consentJourneyContext.Provider value={contextProviderValue} >
      <div className={classes.root}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          fullScreen={Number(windowSize.width) < 600 ? true : false}
        >
          <div>
            <Toolbar className={classes.appBar}>
              <IconButton
                className={classes.icon}
                edge='start'
                color='inherit'
                onClick={backClicked}
                aria-label="close"
              >
                {stage <= 3 && stage > 0 &&
                  <ArrowBack />
                }
              </IconButton>
              <div className={classes.logoContainer}>
                <div>Open-Finance</div>
              </div>
              <IconButton
                className={classes.icon}
                edge='start'
                color='inherit'
                aria-label="close"
                onClick={handleCloseDialog}
              >
                <Close />
              </IconButton>
            </Toolbar>
            {stage <= 3 &&
              <MobileStepper
                variant='progress'
                steps={4}
                position='static'
                activeStep={stage}
                sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}
                nextButton={null}
                backButton={null}
              />
            }
          </div>
          <div className={classes.dialogContent}>
            {stage === 0 && <DisclaimerDialog />}
            {stage === 1 && <ChooseBank />}
            {stage === 2 && <PersonalInfo />}
            {stage === 3 && <Confirm />}
            {stage === 4 && <LoadingScreen />}
            {stage === 5 && <WaitingSessionFinished />}
            {stage === 6 && <JourneyFinished />}
          </div>
        </Dialog >
      </div >
    </consentJourneyContext.Provider>
  )
}

export default ConsentJourney
