import React from 'react'
import { makeStyles } from '@mui/styles'
import { ButtonUnstyled, Alert, Snackbar, Theme } from '@mui/material'
import { theme } from '../../utils'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  getStartedContainer: {
    display: 'flex',
    placeContent: 'center',
  },
  getStartedBtn: {
    display: 'flex',
    margin: '5px',
    fontWeight: 'bold',
    fontSize: 16,
    color: 'white',
    width: '100%',
    height: '50px',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'uppercase',
    borderRadius: spacing(),
    fontFamily: theme.typography.fontFamily,
    borderWidth: 0,
    transitionDuration: '250ms',
    '&:hover': {
      boxShadow: '0px 0px 8px #2c2c2c',
    },
  },
}), { name: 'CustomButton' })

interface Props {
  title: string
  onClick: () => void
  errorMessage?: string
  isError?: boolean
  handleCloseError?: () => void
  style?: React.CSSProperties
}

const CustomButton = (props: Props): JSX.Element => {
  const classes = useStyles()

  const { title, onClick, errorMessage, isError, handleCloseError, style } = props

  return (
    <div className={classes.getStartedContainer}>
      <ButtonUnstyled
        className={classes.getStartedBtn}
        onClick={onClick}
        style={{ backgroundColor: '#112638', ...style }}
      >
        {title}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isError}
          onClose={handleCloseError}
          key={'bottom' + 'center'}
        >
          <Alert severity='error'>{errorMessage}</Alert>
        </Snackbar>
      </ButtonUnstyled>
    </div >
  )
}

export default CustomButton
