import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: 'rgb(68, 69, 67)',
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      contrastText: '#ffcc00',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Inter',
    ].join(','),
  },
}))

export default theme
