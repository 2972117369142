import React from 'react'
import { makeStyles } from '@mui/styles'
import { Theme, Typography } from '@mui/material'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  logo: {
    display: 'flex',
    width: 60,
    height: 60,
    padding: spacing(0.5),
    margin: spacing(0.5),
    alignSelf: 'center',
    borderRadius: 50,
    objectFit: 'cover',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}), { name: 'CustomCard' })

interface Props {
  image: string,
  title: string,
  subTitle: string,
  style?: Record<string, string | number>
}

const CustomCard = (props: Props): JSX.Element => {
  const classes = useStyles()

  const { image, title, subTitle, style } = props

  return (
    <div className={classes.container} style={style}>
      <img src={image} className={classes.logo} alt='logo' />
      <div className={classes.textContainer}>
        <Typography variant='h6'>
          {title}
        </Typography>
        <Typography variant='overline' color='#ababad'>
          {subTitle}
        </Typography>
      </div>
    </div >
  )

}

export default CustomCard

